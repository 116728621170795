import {TextField} from "@material-ui/core";
import {useEffect, useState} from "react";

const BaseRgbColourPicker = ({colour, setColour, divisor}) => {

    const [localColour, setLocalColour] = useState(colour);

    useEffect(() => {
        setLocalColour(colour);
    }, [colour]);

    const onChange = field => e => {
        let newValueRaw = e.target.value;
        setLocalColour({...colour, [field]: newValueRaw});
        if (newValueRaw.endsWith(".")) return;
        const newValue = parseFloat(newValueRaw) * divisor;
        if (isNaN(newValue)) return;
        if (newValue > (255 / divisor)) return;
        if (newValue < 0) return;
        setColour({...colour, [field]: newValue});
    };

    const makeTextFieldValue = field => {
        const local = localColour[field];
        const remote = colour[field];
        if (local === remote) return remote / divisor;
        return local;
    };

    return (
        <div style={{display: "flex"}}>
            <TextField variant="outlined" size="small" style={{flex: 1}} value={makeTextFieldValue("r")} onChange={onChange("r")}/>
            <TextField variant="outlined" size="small" style={{flex: 1}} value={makeTextFieldValue("g")} onChange={onChange("g")}/>
            <TextField variant="outlined" size="small" style={{flex: 1}} value={makeTextFieldValue("b")} onChange={onChange("b")}/>
        </div>
    );

};

export const RgbColourPicker = ({colour, setColour}) => <BaseRgbColourPicker colour={colour} setColour={setColour} divisor={1}/>;
export const FloatRgbColourPicker = ({colour, setColour}) => <BaseRgbColourPicker colour={colour} setColour={setColour} divisor={255}/>;
