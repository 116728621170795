/**
 *
 * @param r
 * @param g
 * @param b
 * @returns {string}
 */
export const rgbToHex = ({r, g, b}) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

/**
 *
 * @param hex {string}
 * @returns {{r: number, b: number, g: number}|null}
 */
export const hexToRgb = (hex) => {
    hex = hex.startsWith("#") ? hex.substr(1) : hex;
    if (hex.length !== 6) return null;
    const r = "0x" + hex[0] + hex[1] | 0;
    const g = "0x" + hex[2] + hex[3] | 0;
    const b = "0x" + hex[4] + hex[5] | 0;
    return {r, g, b};
};

export const invertColour = ({r, g, b}) => {
    return {r: 255 - r, g: 255 - g, b: 255 - b};
};