import {useState} from "react";
import ColourPickerCard from "../components/ColourPickerCard";
import * as Util from "../utils/Util";
import HexColourPicker from "./HexColourPicker";
import {FloatRgbColourPicker, RgbColourPicker} from "./RgbColourPicker";

export const ColourPickers = () => {

    const [colour, setColour] = useState({r: 128, g: 128, b: 128});

    const currentColourHex = Util.rgbToHex(colour);

    return (
        <div style={{background: currentColourHex, minHeight: "100vh", width: "calc(100vw)", overflow: "auto"}}>
            <h1 style={{margin: 32}}>Colour Picker</h1>
            <div style={{width: "calc(100% - 32px)", display: "flex", flexWrap: "wrap", margin: "0 16px"}}>
                <ColourPickerCard title="Hex"><HexColourPicker colour={colour} setColour={setColour}/></ColourPickerCard>
                <ColourPickerCard title="RGB 0-255"><RgbColourPicker colour={colour} setColour={setColour}/></ColourPickerCard>
                <ColourPickerCard title="RGB 0-1"><FloatRgbColourPicker colour={colour} setColour={setColour}/></ColourPickerCard>
            </div>
        </div>
    );
};

export default ColourPickers;

