import Paper from "./Paper";

const ColourPickerCard = ({title, children}) => (
    <div className="colourPickerCard">
        <Paper style={{padding: "16px"}}>
            <h1 style={{marginBottom: 16}}>{title}</h1>
            {children}
        </Paper>
    </div>
);

export default ColourPickerCard;