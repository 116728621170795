import {TextField} from "@material-ui/core";
import {useEffect, useState} from "react";
import * as Util from "../utils/Util";

const HexColourPicker = ({colour, setColour}) => {

    const currentColourHex = Util.rgbToHex(colour);

    const [localText, setLocalText] = useState();

    useEffect(() => setLocalText(currentColourHex), [currentColourHex]);

    const onChange = e => {
        const newHex = e.target.value;
        setLocalText(newHex);
        const newRgb = Util.hexToRgb(newHex);
        if (newRgb != null) {
            setColour(newRgb);
        }
    };

    return (
        <TextField variant="outlined" size="small" style={{width: "100%"}} value={localText} onChange={onChange}/>
    );

};

export default HexColourPicker;